import { AfterViewInit, Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalState } from '../../../global.state';
import { AccountDataGridComponent } from '../../accounts/components/accountDetail/components/accountDataGrid';
import { Util } from '../../../utils/util.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawLongPercentPipe, RipsawPercentPipe } from '../../../theme/pipes';
import { AccountManager } from '../../../utils/accountManager';
import { GlobalDataService } from '../../../globalData';
import { NewInvestmentFinderUserInterface } from '../newInvestmentSelectorModal/components/newInvestmentFinderUserInterface';
import { SecDataStoredInterface } from '../newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import { OverrideHelpers } from '../../../utils/overrideHelpers';
import { BondHelpers } from '../../../utils/bondHelpers';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { GlobalOverride, OverrideGroupSetItem, OverrideKeySetItem, Security } from '../../../utils/dataInterfaces';
import { Account, FormsUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewInvestmentSelectorModalComponent } from '../newInvestmentSelectorModal/newInvestmentSelectorModal.component';
import { OverridesService } from '../../../globalData/overrides.service';
import * as _ from 'lodash-es';
import { OverridesUtil } from '../../../utils/overrides.util';
import { GlossaryUtil } from '../../../utils/glossary.util';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faUndoAlt } from '@fortawesome/pro-light-svg-icons/faUndoAlt';
import { NbTrigger } from '@nebular/theme';
import { EVENT_NAMES } from '../../../utils/enums';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { ButtonOpts } from '../../../reusableWidgets/spinners';
import { Logger } from '../../../utils/logger.service';

@Component( {
  templateUrl: './overrideModal.component.html',
  styleUrls: [ './overrideModal.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class OverrideModalComponent implements AfterViewInit, OnDestroy, NewInvestmentFinderUserInterface {

  faInfoCircle: IconDefinition = faInfoCircle;
  faUndoAlt: IconDefinition = faUndoAlt;
  popoverTrigger: NbTrigger = NbTrigger.HOVER;

  private readonly onDestroy = new Subject<void>();

  position: Position;
  origPosition: Position;
  grid: AccountDataGridComponent;
  account: Account;

  matcher: ErrorStateMatcher = new ErrorStateMatcher();
  form: UntypedFormGroup = new UntypedFormGroup( {} );
  columns: any = {};

  globalKeySets: OverrideGroupSetItem[] = [];
  localKeySet: OverrideGroupSetItem;
  loadingOverrides: boolean = true;

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripLongPercentPipe: RipsawLongPercentPipe = new RipsawLongPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  saveButtonOptions: ButtonOpts = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: false,
    mode: 'indeterminate',
    disabled: false,
  };

  unsavedChanges: boolean = false;
  saveToOverrides: boolean = true;
  callbackFunction: Function;

  modalTitle: string = '';

  saveAccountMappingDone: boolean = false;
  saveGlobalDone: boolean = false;
  shouldCloseAfterSubmission: boolean = true;

  localOverrides: any;
  globalOverride: GlobalOverride;

  positionIsCURUSD: boolean = false;

  tempProxy: Security;

  disableResetAll: boolean = false;

  appName: string = environment.appName;

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _gdService: GlobalDataService,
               private snackBar: MatSnackBar,
               private dialog: MatDialog,
               private dialogRef: MatDialogRef<OverrideModalComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private overrideService: OverridesService,
               private overridesUtil: OverridesUtil,
               private glossaryUtil: GlossaryUtil ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_overrideModal' ] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.init( this.data.row, this.data.account, this.data.grid, this.data.forRevision );
      if ( this.data.callback ) {
        this.callbackFunction = this.data.callback;
      }
    } );
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  // ignoreColumn functions

  /* list of columns to ignore when adding form controls to the form group */
  ignoreColumnDefault( key: OverrideKeySetItem ) {
    return [ 'expenses' ].includes( key.key );
  }

  ignoreColumnsForLoan( key: OverrideKeySetItem ) {
    return [ 'annualized_yield', 'expenses', 'expense_ratio' ].includes( key.key );
  }

  ignoreColumnsForBanking( key: OverrideKeySetItem ) {
    return [ 'expenses', 'expense_ratio' ].includes( key.key );
  }

  accountHasSingleYield() {
    return [ 'Loan', 'Banking' ].includes( this.account?.account_category ) ||
      ( this.account?.isManual && this.account?.account_category === 'Investment' && this.account?.positions[ 0 ]?.security_type === 'Equity' );
  }

  /*
   * Function to setup form controls for all of the columns we want to be able to override
   * */
  setupFormControls() {
    this.loadingOverrides = true;
    this.columns = {};
    this.form = new UntypedFormGroup( {} );
    let ignoreFunc = this.ignoreColumnDefault;
    if ( this.account ) {
      if ( Util.accountIsInvestment( this.account ) ) {

      } else if ( Util.accountIsLoan( this.account ) ) {
        ignoreFunc = this.ignoreColumnsForLoan;
      } else if ( Util.accountIsBanking( this.account ) ) {
        ignoreFunc = this.ignoreColumnsForBanking;
      }
    }

    this.globalKeySets = OverrideHelpers.buildGlobalKeySets( {
      ignoreFunc,
      position: this.position,
      positionOverrides: this.globalOverride?.overrides ?? {},
      state: this._state,
      account: this.account,
    } ); // make clone of the keySets with additional fields based on account
    // category for use only while the modal is tabShown

    // loop through the sets and then the keys in each set and create the form controls with values and the columns object
    for ( const set of this.globalKeySets ) {
      this.setupFormControlForKeys( set.keys );
      for ( const group of set.groups ) {
        this.setupFormControlForKeys( group.keys );
      }
    }
    if ( this.account ) {
      this.localKeySet = OverrideHelpers.buildLocalKeySet( this.position, this.localOverrides, this.account, this._state );

      this.setupFormControlForKeys( this.localKeySet.keys );
      for ( const group of this.localKeySet.groups ) {
        this.setupFormControlForKeys( group.keys );
      }
    }

    this.form.controls.ticker.disable();
    this.form.controls.value?.disable();
    this.loadingOverrides = false;
  }

  setupFormControlForKeys( keys ) {
    for ( const key of keys ) {
      const col = key.key;
      if ( col === 'annualized_yield' ) {
        if ( this.grid ) {
          if ( Util.accountIsBanking( this.account ) ) {
            key.colDef.name = 'Interest Rate';
            key.colDef.long_name = 'Interest Rate';
          }
        }
      }
      if ( col === 'coupon' ) {
        if ( this.grid ) {
          if ( Util.accountIsLoan( this.account ) ) {
            key.colDef.name = 'Loan Rate';
            key.colDef.long_name = 'Loan Rate';
          }
        }
      }

      let value = OverrideHelpers.formatValue( this.position[ col ], col );
      if ( value === undefined ) {
        value = null;
      }
      this.position[ col ] = value;
      this.form.addControl( col, new UntypedFormControl( value ) );

    }
  }

  /*
   * Function to reformat the given input on any change
   * @param input {Object} - html input element that fired an input event
   * @param formControlName {String} - name of the form control to be updated
   * @param type {String} - type of formatting to use when updating the form control
   * */
  onInput( input: any, formControlName: any, type: string ) {
    const selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;
    let inputValue = OverrideHelpers.sanitize( this.form.controls[ formControlName ].value, formControlName, true );
    if ( isNaN( inputValue ) ) {
      inputValue = 0;
    }
    switch ( type ) {
      case 'percent':
        this.form.controls[ formControlName ].setValue( this.ripPercentPipe.transform( inputValue, '2-3' ) );
        break;
      case 'currency':
        this.form.controls[ formControlName ].setValue( this.ripCurrencyPipe.transform( inputValue ) );
        break;
      case 'decimal':
        this.form.controls[ formControlName ].setValue( this.ripDecimalPipe.transform( inputValue, '2-3' ) );
    }
    if ( formControlName === 'price' || formControlName === 'quantity' ) {
      const price = parseFloat( FormsUtil.sanitizeInput( this.form.controls.price.value ) );
      const quantity = parseFloat( FormsUtil.sanitizeInput( this.form.controls.quantity.value ) );
      this.form.controls.value.setValue( this.ripCurrencyPipe.transform( price * quantity ) );
    }
    if ( [ 'loan_term' ].includes( formControlName ) ) {
      this.setMatDateAndStructure();
    }
    input.focus();
    input.setSelectionRange( selectionStart, selectionEnd );
    this.checkForUnsavedChanges();
  }

  onSelectionChange( selectBox: any, key: string ) {
    OverrideHelpers.onSelectionChange( selectBox, key, this.form, this.origPosition );
    this.checkForUnsavedChanges();
  }

  checkForUnsavedChanges() {
    const rawValues = this.form.getRawValue();
    let localChanges: boolean = false;
    if ( this.localOverrides ) {
      localChanges = OverrideHelpers.checkForKeySetsForChanges( [ this.localKeySet ], rawValues, this.localOverrides, this.position );
    }

    let globalChanges: boolean = false;
    if ( this.globalOverride ) {
      globalChanges = OverrideHelpers.checkForKeySetsForChanges(
        this.globalKeySets,
        rawValues,
        ( this.positionIsCURUSD ? this.localOverrides : this.globalOverride.overrides ),
        this.position,
      );
    }

    this.unsavedChanges = globalChanges || localChanges;
  }

  setMatDateAndStructure() {
    if ( this.form.controls.loan_term.value && this.form.controls.loan_origination_date.value ) {
      const matDate = BondHelpers.calcMaturityDate( this.form.controls.loan_origination_date.value, this.form.controls.loan_term.value );
      this.form.controls.maturity_date.setValue( matDate );
      const md = moment( matDate );
      const today = moment();
      const yearsLeft = Math.abs( md.diff( today, 'years' ) );
      // Logger.log( 'setting maturity date' );
      const matStructure = Util.setMaturityStructure( yearsLeft, {}, true );
      this.form.patchValue( matStructure );
      for ( const key of Object.keys( matStructure ) ) {
        Util.updateInputPercentFormat( key, this.form );
      }
    }
  }

  dateChanged( dateInput: any, formControlName: string ) {
    // Logger.log('date changed');
    if ( [ 'loan_origination_date' ].includes( formControlName ) ) {
      this.setMatDateAndStructure();
    }
    this.checkForUnsavedChanges();
  }


  /*
   * tabShown the modal and setup the position and set the data grid component that called this function so we can pass back
   * the data created by the override to the position
   * @param position {Object} - position whose values may be overridden
   * @param grid {AccountDataGridComponent} - account data grid that called for the modal to be opened and contains the
   * position whose values may be overridden
   * */
  init( position: any, account: Account, grid: AccountDataGridComponent, forRevision?: boolean ) {
    this.saveToOverrides = !forRevision;
    this.origPosition = position;
    this.position = Util.clone( this.origPosition );
    this.account = account;
    this.grid = grid;
    this.positionIsCURUSD = this.position.ticker === 'CUR:USD';
    this.localOverrides = OverrideHelpers.getLocalPositionOverrides( this._state, this.origPosition, this.account );
    this.globalOverride = OverrideHelpers.getGlobalOverride( this.origPosition, this._state ) ?? OverrideHelpers.makeNewTempGlobalOverride( this.origPosition );
    this.setupFormControls();
    this.modalTitle = this.getTitle();
    this.disableResetAll = this.thereAreNotOverrides();

    /*setTimeout(() => {
     this.grid?.doChanges();
     this.grid?._cd.detach();
     this._state.globalVars.accountsComponent._cd.detach();
     });*/
  }

  thereAreNotOverrides(): boolean {
    if ( this.localOverrides && Object.keys( this.localOverrides ).length > 0 ) {
      return false;
    }
    return !( this.globalOverride?.overrides && Object.keys( this.globalOverride.overrides ).length > 0 );
  }

  /*
   * close the modal and reset the class variables. Reset everything so the steps will be removed from the
   * dom and the objects from memory until the modal is opened again with a new grid and position
   * */
  close() {
    this.dialogRef.close();
  }

  getOriginalValue() {
    const quantityOverride = this.getFieldOverride( 'quantity' );
    const quantity = quantityOverride ? quantityOverride.old : this.position.quantity;
    const priceOverride = this.getFieldOverride( 'price' );
    const price = priceOverride ? priceOverride.old : this.position.price;
    return quantity * price;
  }

  /*
   * Function called when the form is submitted. This function goes through the form data and determines what fields have
   * been overridden (changed) and creates an object with only those fields and their old and new values
   * */
  submit() {
    this.saveButtonOptions.active = true;
    // get the raw values from the form
    const rawValues = this.form.getRawValue();

    // process local overrides
    if ( this.localOverrides ) {
      this.checkKeySets( [ this.localKeySet ], rawValues, this.localOverrides );
    }

    // process global overrides
    this.checkKeySets( this.globalKeySets, rawValues, ( this.positionIsCURUSD ? this.localOverrides : this.globalOverride.overrides ) );

    // check for global overrides
    if ( Object.keys( this.globalOverride.overrides || {} )?.length > 0 ) {
      if ( !this.globalOverride.id ) {
        this.createGlobalOverride();
      } else {
        this.updateGlobalOverride();
      }
    } else {
      this.saveGlobalDone = true;
    }
    // check for local overrides
    if ( Object.keys( this.localOverrides || {} )?.length > 0 ) {
      this.saveAccountMapping();
    } else {
      this.saveAccountMappingDone = true;
    }
    if ( this.saveGlobalDone && this.saveAccountMappingDone ) {
      this.saveButtonOptions.active = false;
    }


  }

  /**
   * final function for the save process that is called after both local and global saves are completed
   */
  submissionSucceeded() {
    this.grid?.triggerRerenderOfDatatable();
    this.grid?.accountLoaded();
    this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
    this._accountManager.applyOverridesAndScanAgain( this.origPosition, this.localOverrides );
    if ( this.origPosition.real_assets !== undefined && this.origPosition.real_assets > 0 ) {
      this.origPosition.optimizerConstraints = '=';
    }
    this._state.notifyDataChanged( EVENT_NAMES.POSITION_OVERRIDDEN, this.origPosition );
    if ( this.callbackFunction ) {
      this.callbackFunction();
    }
    this.saveButtonOptions.active = false;
    this.snackBar.open( 'Override Saved!', null, Util.getSnackBarOptions() );
  }

  /**
   * Go through all the keys in all the set and check to see if the value has been changed
   * @param keySets - which key sets to check, global or local
   * @param rawValues - raw form values
   * @param overrides - overrides
   */
  checkKeySets( keySets: OverrideGroupSetItem[], rawValues: any, overrides: any ) {
    for ( const set of keySets ) {
      this.checkOverridesForKeys( set.keys, rawValues, overrides );
      for ( const group of set.groups ) {
        this.checkOverridesForKeys( group.keys, rawValues, overrides );
      }
    }
  }

  /**
   * Function that uses the global overrides api to create a new global override for the current user
   */
  createGlobalOverride() {
    this.overrideService.createOverride( this.globalOverride )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // load the new global override into the global overrides map in the global state
          this._state.globalVars.globalOverrides[ resp?.data?.id ] = resp?.data;
          this.origPosition.global_override_id = resp?.data?.id;
          this.position.global_override_id = resp?.data?.id;
          this._accountManager.addNewGlobalOverrideIdToPositions( resp.data );
          this.snackBar.open( 'Global Overrides Saved!', null, Util.getSnackBarOptions() );
          this.saveGlobalDone = true;
          this.checkIfBothSavesCompleted();
        }, error: ( err ) => {
          this.snackBar.open( `Error Saving Global Overrides: ${ err.err }`, 'dismiss', Util.getSnackBarOptions( true ) );
          this.saveButtonOptions.active = false;
        },
      } );
  }

  /**
   * Function that uses the global overrides api to create a new global override for the current user
   */
  updateGlobalOverride() {
    if ( this.globalOverride ) {
      this.overrideService.updateOverride( this.globalOverride )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this._state.globalVars.globalOverrides[ this.globalOverride.id ] = resp.data;
            this.snackBar.open( 'Global Overrides Saved!', null, Util.getSnackBarOptions() );
            this.saveGlobalDone = true;
            this.checkIfBothSavesCompleted();
          }, error: ( err ) => {
            this.snackBar.open( `Error Saving Global Overrides: ${ err.err }`, 'dismiss', Util.getSnackBarOptions( true ) );
            this.saveButtonOptions.active = false;
          },
        } );
    } else {
      this.saveGlobalDone = true;
      this.checkIfBothSavesCompleted();
    }
  }

  saveAccountMapping() {
    const mapping = this._state.globalVars.accountMapping.mapping;
    const accountOverrides = mapping[ this.account?.connection_id ]?.[ this.account?.account_id ]?.overrides || {};
    accountOverrides[ this.origPosition.id ] = this.localOverrides;
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // this block is temporary and will only be needed to remove old override objects from pre-global implementation
    const correctTicker = this.getCorrectTicker( this.localOverrides );
    if ( accountOverrides[ correctTicker ] && ( String( correctTicker ) !== String( this.origPosition.id ) ) ) {
      delete accountOverrides[ correctTicker ];
    }
    mapping[ this.account.connection_id ][ this.account.account_id ].overrides = accountOverrides;
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    this._gdService.updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( /*resp: any*/ ) => {
          this.saveAccountMappingDone = true;
          this.checkIfBothSavesCompleted();
        }, error: ( err: any ) => {
          this.snackBar.open( `Error Updating Account Mapping: ${ err }`, null, Util.getSnackBarOptions() );
        },
      } );
  }

  /**
   * function to check if both saves are done to call the completion function
   */
  checkIfBothSavesCompleted() {
    if ( this.saveAccountMappingDone && this.saveGlobalDone ) {
      this.submissionSucceeded();
      if ( this.shouldCloseAfterSubmission ) {
        this.close();
      } else {
        this.shouldCloseAfterSubmission = true;
      }
    } else {
      Logger.log( 'only one save completed' );
    }
  }

  /**
   *
   * @param keys - set of keys to be checked
   * @param rawValues - raw values from the form
   * @param positionOverrides - map of overrides for this position
   */
  checkOverridesForKeys( keys, rawValues, positionOverrides ) {
    for ( const key of keys ) {
      const col = key.key;
      // remove any formatting from the raw value
      const sanitizedValue = rawValues[ col ] === '' || rawValues[ col ] === '-' || rawValues[ col ] === null ?
        '' : OverrideHelpers.sanitize( rawValues[ col ], col, true );

      if ( this.saveToOverrides ) {
        // if there is already an override for this field, check to see if the new value has been changed. also set the
        // value in the position
        if ( positionOverrides[ col ] ) {
          if ( positionOverrides[ col ].new !== sanitizedValue ) {
            positionOverrides[ col ].new = sanitizedValue;
            this.updatePositionValues( col, sanitizedValue );
          }
        } else {
          // if there wasn't an override for this field, create one and set both new and old values. also set the value
          // in the position
          // sanitize is changing null to 0, so the comparison to a 0 that is entered is stopping an override of "0" from being applied
          if ( sanitizedValue !== '' && OverrideHelpers.getCurrentValueSanitized( this.position[ col ], col ) !== sanitizedValue ) {
            positionOverrides[ col ] = { old: this.origPosition[ col ], new: sanitizedValue };
            this.updatePositionValues( col, sanitizedValue );
          }
        }
      } else {
        this.updatePositionValues( col, sanitizedValue );
      }
    }
  }

  updatePositionValues( key, value ) {

    if ( key === 'ticker' ) {
      this.origPosition.overridden_ticker = this.origPosition.ticker;
    }
    this.origPosition[ key ] = value; // update the position field value
    // gotta update the copy value too so the current aggregation recalculates right
    const currentPosition = this._accountManager.getAllOriginalPositionsIncludingManualAccounts().find( ( p: any ) => {
      return p.account_id === this.origPosition.account_id && p.ticker === this.origPosition.ticker;
    } );
    if ( currentPosition ) {
      currentPosition[ key ] = value;
    }
    if ( this.account?.account_category?.toUpperCase() === 'LOAN' ) {
      this.updateLoanPositionValues( this.origPosition, currentPosition );
    }
  }

  updateLoanPositionValues( origPosition: any, position: any ) {
    for ( const p of [ origPosition, position ] ) {
      this._accountManager.setLoanFields( p );
    }
  }

  /*
   * Function for removing an override from the map and resetting the position value to the old value
   * @param key {String} - key for the field whose override needs to be removed
   * */
  removeSingleOverrideAndSave( key: string ) {

    this.shouldCloseAfterSubmission = false;
    this.removeOverride( key );

    // this block is for the price, quantity and value fields which are interdependent. if one is removed, they all need to be
    const pqvKeys = [ 'price', 'quantity', 'value' ];
    if ( pqvKeys.includes( key ) ) {
      _.remove( pqvKeys, ( k: string ) => {
        return k === key;
      } );
      pqvKeys.forEach( ( k: string ) => {
        this.removeOverride( k );
      } );
      this.position.value = OverrideHelpers.sanitize( this.form.controls.price.value, 'price', true ) *
        OverrideHelpers.sanitize( this.form.controls.quantity.value, 'quantity', true );
      this.form.controls.value.setValue(
        OverrideHelpers.formatOldOverrideValue( 'value', this.position.value ),
      );
    }

    if ( OverrideHelpers.isFieldLocal( key, this.origPosition ) ) {
      this.saveAccountMapping();
      this.saveGlobalDone = true;
    } else {
      this.updateGlobalOverride();
      this.saveAccountMappingDone = true;
    }

    // }
  }

  /**
   * Function to remove an override from a position/security
   * @param key - key of the override to remove
   */
  removeOverride( key: string ) {

    if ( this.doesFieldHaveOverride( key ) ) {
      const oldValue = this.getFieldOverride( key ).old;
      this.updatePositionValues( key, oldValue );
      const formattedOldValue = OverrideHelpers.formatOldOverrideValue( key, oldValue );
      this.form.controls[ key ].setValue( formattedOldValue );
      this.position[ key ] = oldValue;
      this.origPosition[ key ] = oldValue;
      // this.position[key] = formattedOldValue;
      // this.origPosition[key] = formattedOldValue;

      if ( OverrideHelpers.isFieldLocal( key, this.origPosition ) ) {
        delete this.localOverrides[ key ];
      } else {
        delete this.globalOverride.overrides[ key ];
      }

      this.clearOverrideInfoFromKeySets( key );
      // this.doChanges();
    }
  }

  getCorrectTicker( overrides?: any ) {
    if ( this.globalOverride ) {
      return this.globalOverride?.original_ticker;
    }
    if ( overrides ) {
      if ( overrides[ this.origPosition.ticker ] ) {
        return this.origPosition.ticker;
      } else {
        const oldTicker = OverrideHelpers.getOverrideByNewTicker( this.origPosition.ticker, overrides )?.ticker?.old;
        if ( oldTicker ) {
          return oldTicker;
        }
      }
    }
  }

  /**
   *
   * @param keyToClear - key of the field that needs to be cleared
   */
  clearOverrideInfoFromKeySets( keyToClear: string ) {
    if ( OverrideHelpers.isFieldLocal( keyToClear, this.origPosition ) ) {
      OverrideHelpers.clearOverrideInfoFromKeySet( keyToClear, this.localKeySet, this.position );
    } else {
      for ( const set of this.globalKeySets ) {
        OverrideHelpers.clearOverrideInfoFromKeySet( keyToClear, set, this.position );
      }
    }
  }

  /*
   * Function for removing all overrides on this position
   * */
  removeAllOverrides() {
    this.shouldCloseAfterSubmission = false;

    // remove global keys
    for ( const set of this.globalKeySets ) {
      this.removeAllHelper( set.keys );
      for ( const group of set.groups ) {
        this.removeAllHelper( group.keys );
      }
    }
    // remove local keys if necessary
    if ( this.localOverrides ) {
      this.removeAllHelper( this.localKeySet.keys );
      for ( const group of this.localKeySet.groups ) {
        this.removeAllHelper( group.keys );
      }
    }
    // if this is not a CUR:USD position, we should remove the globalOverride
    if ( !this.positionIsCURUSD || this.globalOverride.id ) {
      this.overridesUtil.deleteGlobalOverride( this.globalOverride );
    }
    if ( this.grid ) {
      this.saveAccountMapping();
    }
    this._accountManager.scanPositionsForMissingData( [ this.origPosition ] );
    this.overridesUtil.updateAllCopiesOfPositionAfterRemove( this.origPosition );
    if ( this.origPosition.overridden_quantity ) {
      this.origPosition.quantity = this.origPosition.overridden_quantity;
      delete this.origPosition.overridden_quantity;
    }
    if ( this.origPosition.overridden_price ) {
      this.origPosition.price = this.origPosition.overridden_price;
      delete this.origPosition.overridden_price;
    }
    delete this.origPosition.overridden_ticker;
    delete this.position.overridden_ticker;
    Object.assign( this.position, this.origPosition );
    this.init( this.origPosition, this.account, this.grid, this.data.forRevision );
    this._state.notifyDataChanged( 'overrides.removed', {} );
  }

  removeAllHelper( keys: OverrideKeySetItem[] ) {
    for ( const key of keys ) {
      this.removeOverride( key.key );
    }
  }

  /*
   * Function for checking if the given field has an override
   * @param key {String} - field to check to see if it has an override
   * */
  doesFieldHaveOverride( key: string ) {
    return OverrideHelpers.doesFieldHaveOverride( key, this.localOverrides, this.globalOverride?.overrides, this.origPosition );
  }

  /*
   * Function for getting the override for the given string
   * @param key {String} - the field to retrieve the override for
   * */
  getFieldOverride( key: string ) {
    if ( this.doesFieldHaveOverride( key ) ) {
      if ( OverrideHelpers.isFieldLocal( key, this.origPosition ) ) {
        return this.localOverrides[ key ];
      } else {
        return this.globalOverride.overrides[ key ];
      }
    }
  }

  /*
   * Get a title for the modal based on the position and account that were passed in when the modal was opened
   * @returns a string that is displayed as a title in the modal with account and position info
   * */
  getTitle() {
    let val = `Security Override Values`;
    if ( this.position ) {
      if ( this.position.ticker === 'CUR:USD' ) {
        val = `${ val } For ${ this.account.formattedDescription }`;
      } else {
        val = `${ val } For ${ this.origPosition.overridden_ticker ?? this.origPosition.ticker }`;
      }
      // position?.ticker === 'CUR:USD' ? position?.formatted_account_description : position?.ticker
      /*if ( this.grid ) {
       val = `${ val } in account ${ Util.formatAccountDescription( this.account ) }`;
       }*/
    }
    return val;
  }

  newSecuritiesChosen( chosenSecurityData: SecDataStoredInterface[] ) {
    if ( chosenSecurityData && chosenSecurityData.length > 0 ) {
      Logger.log( `new securities chosen: ${ chosenSecurityData }` );
      const chosen = chosenSecurityData[ 0 ].securityData;
      this.tempProxy = chosen;
      const keySets = [ ...this.globalKeySets ];
      if ( this.localKeySet ) {
        keySets.push( this.localKeySet );
      }
      for ( const set of keySets ) {
        this.setProxyValues( set.keys, chosen );
        for ( const group of set.groups ) {
          this.setProxyValues( group.keys, chosen );
        }
      }
      if ( this._state.globalVars.investmentSelector ) {
        this._state.globalVars.investmentSelector.close();
      }
      if ( this.account ) {
        this.localKeySet = OverrideHelpers.buildLocalKeySet( this.position, this.localOverrides, this.account, this._state );
      }
      this.checkForUnsavedChanges();
    }
  }

  setProxyValues( keys, proxy ) {
    for ( const key of keys ) {
      const col = key.key;
      if ( ![ /*'price', 'quantity',*/ 'value' ].includes( col ) ) {

        if ( key.key === 'price' ) {
          this.position.overridden_price = this.origPosition.price;
          this.position.price = proxy.price;
          key.fieldHasOverride = true;
          key.formattedOldOverrideValue = OverrideHelpers.formatOldOverrideValue( key, this.position.overridden_price );
        }

        if ( key.key === 'quantity' ) {
          this.position.overridden_quantity = this.origPosition.yodlee_original_data.quantity;
          this.position.quantity = this.origPosition.value / proxy.price;
          key.fieldHasOverride = true;
          key.formattedOldOverrideValue = OverrideHelpers.formatOldOverrideValue( key, this.position.overridden_quantity );
          this.form.controls.quantity.setValue( OverrideHelpers.formatValue( this.position.quantity, key.key ) );
        }

        if ( key.key === 'ticker' && !this.position.overridden_ticker ) {
          this.position.overridden_ticker = this.origPosition.ticker;
        }
        const newSecValue = OverrideHelpers.formatValue( proxy[ col ], col );
        if ( newSecValue || newSecValue === 0 ) {
          this.form.controls[ col ].setValue( newSecValue );
        }
      }
    }
  }

  investmentFinderMessage: string = 'If you choose a new security as a proxy, ' +
    'all the fields in the security that have values will override the values of this security';

  openInvestmentSelector() {
    // this._state.globalVars.investmentSelector.open( this, false );
    this.dialog.open( NewInvestmentSelectorModalComponent, {
      data: {
        finderUser: this,
      },
      width: '85vw',
      disableClose: false,
      hasBackdrop: true,
    } );
  }

  chooseRate( rate: any ) {
    this.form.controls.current_market_rate.setValue( rate.refi.rate * 100 );
    Util.updateInputPercentFormat( 'current_market_rate', this.form, true );
  }

  removeNewSecurity() {
    this.grid?.removeNewSecurity( this.origPosition.ticker );
    this.close();
  }

  /*
   * Function to open the glossary dialog for displaying the disclaimers
   * */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

}

