import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ColumnDefinition, GlobalState } from '../../global.state';
import { Util } from '../../utils/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountManager } from '../../utils/accountManager';
import * as _ from 'lodash-es';
import { RipsawCurrencyPipe, RipsawDatePipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../theme/pipes';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { AllocCalculator, Holding, HoldingPosition } from '@ripsawllc/ripsaw-analyzer';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OneDayChangeUtil } from '../../utils/oneDayChangeUtil';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { DisclaimersUtil } from '../../utils/disclaimers.util';
import { BaseChartDirective } from 'ng2-charts';
import { ChartColorUtil } from '../../utils/chart-color.util';
import { OverrideModalComponent } from '../../pages/modals/overrideModal';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { EVENT_NAMES } from '../../utils/enums';
import { WithWorkspacePermission } from '../../shared/workspace-permission';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';


@Component( {
  selector: 'rip-holdings',
  templateUrl: './holdings.component.html',
  styleUrls: [ '../../pages/holdings/holdings.scss' ],
} )

export class HoldingsComponent extends WithWorkspacePermission implements AfterViewInit, OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();

  faCaretDown: IconDefinition = faCaretDown;
  faCaretUp: IconDefinition = faCaretUp;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faUniversity: IconDefinition = faUniversity;
  faEdit: IconDefinition = faEdit;

  @ViewChild( 'oneDayChangeTemplate', { static: true } ) oneDayChangeTemplate: TemplateRef<any>;
  @ViewChild( 'priceTemplate', { static: true } ) priceTemplate: TemplateRef<any>;
  @ViewChild( 'descriptionTemplate', { static: true } ) descriptionTemplate: TemplateRef<any>;
  @ViewChild( 'headerTemplate', { static: true } ) headerTemplate: TemplateRef<any>;
  @ViewChild( 'rowDetailCellTemplate', { static: false } ) rowDetailCellTemplate: TemplateRef<any>;
  // @ViewChild( 'holdingsToolbar', { static: false } ) holdingsToolbar: HoldingsToolbarComponent;
  @ViewChildren( DatatableComponent ) viewChildren: QueryList<DatatableComponent>;
  @ViewChild( 'holdingsChart' ) chartDirective: BaseChartDirective;
  @ViewChild( 'overrideTemplate', { static: true } ) overrideTemplate: TemplateRef<any>;

  getDataGrid(): DatatableComponent {
    return this.viewChildren.find( ( child: DatatableComponent ) => {
      return !!child;
    } );
  }

  resizeTimeout;

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( /*event: Event*/ ) {
    // Logger.info( 'there was a resize event' );
    clearTimeout( this.resizeTimeout );
    this.resizeTimeout = setTimeout( () => {
      // Logger.info( 'resizeTimeout finished' );
      this.setCommonColumnProperties();
      this.setTruncationNumber();
      this.triggerRerenderOfDatatable();
    }, 500 );
  }

  truncationNumber: number = 30;

  topBarMargin: number = 195;
  spaceBetweenDashboardAndHoldings: number = 30;
  pricesRefreshing: boolean = false;
  progressValue: number = 0;

  holdings: Map<string, any> = new Map<string, any>();
  rows: any[] = [];
  columns: any[] = [];
  leftSideColumns: any[] = [];
  sortedColumns = {};
  lastSort: any = {};
  overrideColumn: ColumnDefinition = {};

  total: number;

  messages = {
    emptyMessage: 'No holdings',
    totalMessage: 'Holdings',
  };

  cssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'fa fa-caret-left',
    pagerRightArrow: 'fa fa-caret-right',
    pagerPrevious: 'fa fa-step-backward',
    pagerNext: 'fa fa-step-forward',
  };

  defaultColumnSets: any[] = this._state.defaultColumnSets;

  tableHeaderHeight: number = 35;
  tableRowHeight: number = 55;

  ripDatePipe: RipsawDatePipe = new RipsawDatePipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  subscriberName: string = 'HoldingsComponent';

  filters: any = {};

  maxHeight: string;
  chartMaxHeight: string;

  readonly spinnerSelector: string = 'holdings-spinner';

  deviceIsMobile: boolean = false;
  mobileColumnsList = [ 'ticker', 'one_day_change', 'value' ];

  dataRetrieved: boolean = false;

  spinnerOptions: any = {};

  flip: string = 'inactive';
  showTable: boolean = true;

  // chart variables
  chartData: ChartDataset[] = [ { data: [], hoverOffset: 20 } ];
  chartTickers: string[] = [];
  chartLabels: string[] = [];
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: ( event, items ) => {
      if ( items.length > 0 ) {
        const item = items[ 0 ];
        this.chartDirective.chart.update();
        this.goToRowDetail( this.chartTickers[ item.index ] );
      }
    },

    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: 'Holdings Allocation',
        color: '#1B75BC',
        padding: 25,
        font: {
          weight: 'bolder',
          size: this._state.chartTitleHeight,
        },
      },
      legend: {
        position: 'right',
        onClick: ( event, item ) => {
          this.goToRowDetail( this.chartTickers[ item.index ] );
        },
        onLeave: ( event, legendItem ) => {
          const chart: any = this.chartDirective.chart;
          chart.update();
        },
      },
      tooltip: {
        callbacks: {
          label: ( context: TooltipItem<ChartType> ) => {
            return context.label;
          },
        },
      },
    },


    layout: {
      padding: {
        bottom: 25,
      },
    },
  };
  plugins: any[] = [];
  legend: boolean = true;
  chartType: ChartType = 'pie';

  constructor( private _state: GlobalState,
               private snackBar: MatSnackBar,
               private _accountManager: AccountManager,
               private _elRef: ElementRef,
               private _router: Router,
               private _detectorService: DeviceDetectorService,
               private disclaimersUtil: DisclaimersUtil,
               private _cd: ChangeDetectorRef,
               public dialog: MatDialog,
               private route: ActivatedRoute ) {
    super();

    this._accountManager.allDataRetrieved.pipe( takeUntil( this.onDestroy ) ).subscribe( {
      next: ( val: boolean ) => {
        this.dataRetrieved = val;
      },
    } );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_holdings' ] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    if ( this.deviceIsMobile ) {
      this.tableHeaderHeight = 30;
      this.tableRowHeight = 50;
    }

    if ( this.deviceIsMobile ) {
      this.spinnerOptions = {
        repositionSpinner: { top: '45%', left: '30%' },
      };
    } else {
      this.spinnerOptions = {
        repositionSpinner: { top: '40%', left: '43%' },
      };
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this._state.subscribe( EVENT_NAMES.WIDGET_RESIZE, ( height: number ) => {
        this.topBarMargin = height + this.spaceBetweenDashboardAndHoldings;
        // Logger.info( `changed margin-top to: ${height}` );
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.PRICES_UPDATED, ( err ) => {
        if ( err ) {
          this.snackBar.open( `Error updating prices. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
        } else {
          // this.snackBar.open( 'Prices Updated Successfully', null, Util.getSnackBarOptions() );
          this.aggregateHoldings();
        }
        this.pricesRefreshing = false;
        this.doChanges();
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.PROGRESS_UPDATE, ( progress: number ) => {
        this.progressValue = progress;
        this.doChanges();
      }, this.subscriberName );

      this._state.subscribe( [
        EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
        EVENT_NAMES.MANUAL_ACCOUNT_CREATED,
        EVENT_NAMES.MANUAL_ACCOUNT_UPDATED,
        EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
        EVENT_NAMES.POSITION_OVERRIDDEN,
        'overrides.removed',
        'RE.toggled' ].join( ' | ' ), () => {
        this.toggleInfoType( this._state.globalVars.infoType );
        this.aggregateHoldings();
        this.onSort( { target: { id: 'one_day_change' }, dir: 'asc' } );
      }, this.subscriberName );

      this._state.subscribe( 'goto.column.group', ( group: any ) => {
        Util.scrollToColumn( group, this );
      }, this.subscriberName );

      this._state.subscribe( 'infoType.changed', ( infoType ) => {
        this.toggleInfoType( infoType );
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.MENU_IS_COLLAPSED, ( /*isCollapsed: boolean*/ ) => {
        this.triggerRerenderOfDatatable();
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.ACCOUNTS_LOADING_ERROR, () => {
        this.dataRetrieved = true;
      }, this.subscriberName );


      this.topBarMargin = this._state.getGlobalDashboardHeight() + this.spaceBetweenDashboardAndHoldings;

      this.overrideColumn = {
        name: '',
        prop: 'override',
        frozenLeft: true,
        cellTemplate: this.overrideTemplate,
        cellClass: 'override-column',
        maxWidth: 40,
      };

      this.leftSideColumns = [
        {
          name: '',
          prop: 'row_detail',
          frozenLeft: true,
          cellTemplate: this.rowDetailCellTemplate,
          sortable: false,
          resizeable: false,
          canAutoResize: false,
          draggable: false,
          maxWidth: 40,
          width: 40,
        },
        this.overrideColumn,
        {
          name: 'Ticker',
          prop: 'ticker',
          frozenLeft: true,
          cellClass: Util.getCellClass,
        },
        {
          name: 'Description',
          prop: 'ticker_name',
          frozenLeft: true,
          cellTemplate: this.descriptionTemplate,
          cellClass: Util.getCellClass,
        },
        {
          name: 'Type',
          longName: 'Security Type',
          prop: 'security_type',
        },
        {
          name: 'Price',
          prop: 'price',
          cellTemplate: this.priceTemplate,
          disclaimer: 3,
          minWidth: 110,
        },
        {
          name: `1-Day Return`,
          prop: 'one_day_change',
          cellTemplate: this.oneDayChangeTemplate,
          cellClass: 'one-day-change-cell',
          minWidth: 150,
          disclaimer: 0,
        },
        {
          name: 'Quantity',
          longName: 'Number of Shares',
          prop: 'quantity',
          pipe: this.ripDecimalPipe,
        },
        {
          name: 'Value',
          longName: 'Market Value',
          prop: 'value',
          pipe: this.ripCurrencyPipe,
          minWidth: 110,
        },
        {
          name: 'Allocation',
          prop: 'allocation',
          pipe: this.ripPercentPipe,
        },
      ];

      if ( !this._state.globalVars.loadingAccounts || this._state.globalVars.accountsCameFromWF ) {
        this.aggregateHoldings();
        this.onSort( { target: { id: 'one_day_change' }, dir: 'asc' } );
        this.checkForRowToOpen();
        this.dataRetrieved = true;
      }

      if ( this.deviceIsMobile ) {
        this.columns = [ ...this.leftSideColumns.filter( ( col: any ) => {
          return this.mobileColumnsList.includes( col.prop );
        } ) ];
      } else {
        this.columns = [
          ...this.leftSideColumns,
          ...this.getColumns( this._state.globalVars.infoType ),
        ];
      }

      this.setCommonColumnProperties();
      this.setTruncationNumber();
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.WIDGET_RESIZE,
      EVENT_NAMES.PRICES_UPDATED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.PROGRESS_UPDATE,
      'goto.column.group',
      'infoType.changed',
      EVENT_NAMES.MENU_IS_COLLAPSED,
      'RE.toggled',
      EVENT_NAMES.MANUAL_ACCOUNT_CREATED,
      EVENT_NAMES.MANUAL_ACCOUNT_UPDATED,
      EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
      EVENT_NAMES.POSITION_OVERRIDDEN,
      'overrides.removed',
      EVENT_NAMES.ACCOUNTS_LOADING_ERROR ].join( ' | ' ), this.subscriberName );
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    // this._cd.markForCheck();
    this._cd.reattach();
  }

  setTruncationNumber() {
    this.truncationNumber = window.innerWidth / 60;
  }

  checkForRowToOpen() {
    this.route.queryParams
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( params: Params ) => {
        if ( params.rowToOpen ) {
          this.goToRowDetail( params.rowToOpen );
        }
      } );
  }

  aggregateHoldings() {
    // reset variables
    this.holdings = new Map<string, any>();
    this.total = 0;
    this.chartData = [ { data: [], hoverOffset: 20 } ];
    this.chartTickers = [];
    this.chartLabels = [];


    this._accountManager.getAllOriginalAccounts().forEach( ( a: any ) => {
      if ( a.positions && a.positions.length > 0 ) {
        const firstPosition = a.positions[ 0 ];
        const isNotCorrespondingAccount = AllocCalculator.isNotAssetOrCorrespondingLiability( firstPosition, this._accountManager.getAllRevisableAccounts() );
        if ( this._state.globalVars.showRE || isNotCorrespondingAccount ) {
          if ( a.isManual && a.account_type.toLowerCase() !== 'investment' ) {
            if ( this.shouldNotFilterOut( firstPosition ) ) {
              if ( Util.accountIsBanking( a ) ) {
                if ( !this.holdings.get( 'CUR:USD' ) ) {
                  this.holdings.set( 'CUR:USD', {
                    ticker: 'CUR:USD',
                    ticker_name: 'US Dollar',
                    security_type: 'Cash',
                    price: 1,
                    quantity: firstPosition.value,
                    value: firstPosition.value,
                    positions: [ { account: a, position: firstPosition } ],
                    shouldBeFullWidth: true,
                  } );
                } else {
                  const h = this.holdings.get( 'CUR:USD' );
                  h.quantity += firstPosition.value;
                  h.value += firstPosition.value;
                  h.positions.push( { account: a, position: firstPosition } );
                }
              } else if ( Util.accountIsPensionOrSS( a ) ) {
                if ( this.shouldNotFilterOut( firstPosition ) ) {
                  this.addAccountHolding( firstPosition, a, { isSSorPension: true } );
                }
              } else if ( Util.accountIsLoan( a ) ) {
                if ( this.shouldNotFilterOut( firstPosition ) ) {
                  const isDebt: boolean = a.value < 0;
                  this.addAccountHolding( firstPosition, a, { isDebt, isLoan: true } );
                }
              } else /*if ( Util.accountIsManualBond( a ) )*/ {
                this.addAccountHolding( firstPosition, a );
              }
              this.total += firstPosition.value;
            }
          } else if ( Util.accountIsLoan( a ) ) {
            if ( this.shouldNotFilterOut( firstPosition ) ) {
              const isDebt: boolean = a.value < 0;
              this.addAccountHolding( firstPosition, a, { isDebt, isLoan: true } );
            }
          } else if ( Util.accountIsCreditCard( a ) ) {
            if ( this.shouldNotFilterOut( firstPosition ) ) {
              this.addAccountHolding( firstPosition, a, { isCredit: true } );
            }
          } else {
            a.positions.forEach( ( position: any ) => {
              position.value = position.quantity * parseFloat( position.price ); // need this to make sure value is updated based on newest price
              if ( position.cost_basis ) {
                position.gain_loss = position.value - position.cost_basis;
              }
              if ( this.shouldNotFilterOut( position ) ) {
                if ( !position.account_id ) {
                  position.account_id = a.account_id;
                }
                if ( !this.holdings.get( position.ticker ) ) {

                  const newHolding = _.cloneDeep( position );
                  if ( ( ( newHolding.security_type === 'Mutual Fund' && !newHolding.investment_strategy ) || newHolding.security_type === 'Money Market' || newHolding.security_type === 'Money Market Fund' || position.ticker === 'CUR:USD' ) ) {
                    newHolding.shouldBeFullWidth = true;
                  }
                  newHolding.positions = [ { account: a, position } ];
                  if ( newHolding.investment_strategy ) {
                    newHolding.investment_strategy = newHolding.investment_strategy.replace( /\s{2,10}/g, ' ' );
                  }
                  this.holdings.set( position.ticker, newHolding );
                } else {

                  const holding = this.holdings.get( position.ticker );
                  holding.positions.push( {
                    account: a,
                    position,
                  } );
                  holding.quantity += position.quantity;
                  holding.value = holding.quantity * parseFloat( holding.price );
                }

                this.total += position.value;
              }
            } );
          }
        }
      }
    } );
    // Logger.info( `total: ${ this.total }` );
    this.rows = [ ...this.holdings.values() ];
    this.rows.forEach( ( h: any ) => {
      OneDayChangeUtil.calcOneDayChange( h, this._state.globalVars.todayIsTradingDay );
      h.allocation = h.value / this.total;
      this.addHoldingToChartDataset( h );
      // h.missing_data = h.positions[0].missing_data;
    } );

    const colors = ChartColorUtil.getColorGradients( this.chartData[ 0 ].data.length );
    this.chartData[ 0 ].backgroundColor = colors;
    this.chartData[ 0 ].hoverBackgroundColor = colors;
    this.chartData[ 0 ].borderWidth = 1;


    if ( this._accountManager.overriddenSecuritiesBeingRetrieved === 0 && this.rows.length > 0 ) {
      this.dataRetrieved = true;
    }
  }

  addAccountHolding( firstPosition: any, a: any, options?: any ) {
    if ( options ) {
      if ( options.isLoan ) {
        firstPosition.isLoan = true;
        firstPosition.cost_basis = firstPosition.cost_basis || firstPosition.outstanding_balance;
        if ( options.isDebt ) {
          firstPosition.isDebt = true;
          if ( firstPosition.cost_basis ) {
            firstPosition.gain_loss = firstPosition.value - ( Math.abs( firstPosition.cost_basis ) * -1 );
          }
        } else {
          // if isDebt was false and isLoan is true, then it is an asset and the value is positive
          if ( firstPosition.cost_basis ) {
            firstPosition.gain_loss = firstPosition.value - ( Math.abs( firstPosition.cost_basis ) ); // not sure if this is right
          }
        }
      }
      if ( options.isCredit ) {
        firstPosition.isCredit = true;
      }
      if ( options.isSSorPension ) {
        firstPosition.isSSorPension = true;
      }
    } else {
      if ( firstPosition.cost_basis ) {
        firstPosition.gain_loss = firstPosition.value - firstPosition.cost_basis;
      }
    }
    const newHolding = _.cloneDeep( firstPosition );
    if ( newHolding.security_type !== 'Equity' && newHolding.security_type !== 'Crypto' ) {
      newHolding.shouldBeFullWidth = true;
    }
    const formattedDescription = Util.formatAccountDescription( a, true );
    newHolding.positions = [ { account: a, position: firstPosition } ];
    if ( !newHolding.ticker || newHolding.ticker === '' ) {
      newHolding.ticker = formattedDescription;
    }
    newHolding.ticker_name = formattedDescription;
    this.holdings.set( a.account_id, newHolding );
  }

  addHoldingToChartDataset( h: Holding ) {
    this.chartData[ 0 ].data.push( h.allocation );
    this.chartTickers.push( h.ticker );
    this.chartLabels.push( `${ h.ticker_name }: ${ this.ripPercentPipe.transform( h.allocation ) }` );
  }

  shouldNotFilterOut( position: any ) {
    if ( !this.filters.column ) {
      return true;
    }
    return this.doOperation[ this.filters.operator ]( position[ this.filters.column.prop ], this.filters.value );
  }

  doOperation: any = {
    '>': ( x, y ) => {
      return parseFloat( x ) > parseFloat( y );
    },
    '<': ( x, y ) => {
      return parseFloat( x ) < parseFloat( y );
    },
    '=': ( x, y ) => {
      if ( isNaN( y ) ) {
        if ( typeof y === 'string' ) {
          return x.toLowerCase() === y.toLowerCase();
        } else {
          return x === y;
        }
      } else {
        return parseFloat( x ) === parseFloat( y );
      }
    },

  };

  getColumns( type: string ) {
    const columnSet: any = _.find( this._state.getAllColumnSets(), ( set: any ) => {
      return set.label === type;
    } );
    const cols = columnSet.columns;
    // use this spot to remove unnecessary columns from this account grid
    return this._state.makeColumnSet( cols, 'account' ) || [];
  }

  onMatTableSort( columnProp: string ) {
    this.onSort( {
      target: {
        id: columnProp,
      },
    } );
  }

  onSort( event: any ) {
    let holdingsArray = [ ...this.rows ];
    // Logger.info( `sorting on ${event.target.id}` );
    const sort = event.target.id;
    let dir = event.dir || 'asc';
    // check to see if columns are already sorted
    if ( this.sortedColumns[ sort ] && this.sortedColumns[ sort ] === 'asc' ) {
      // already sorted ascending, so sort descending
      dir = 'dsc';
      this.sortedColumns[ sort ] = dir;
    } else {
      // already sorted descending or not sorted at all, so sort ascending
      this.sortedColumns[ sort ] = dir;
    }
    const sortedArray = Util.sortPositions( sort, holdingsArray, dir );
    if ( sortedArray ) {
      holdingsArray = sortedArray;
    }
    this.lastSort = { sort, dir };
    this.rows = [ ...holdingsArray ];
  }

  removeColumns( colsToRemove: string[] ) {
    const colMap = {};
    for ( const col of colsToRemove ) {
      colMap[ col ] = true;
    }
    _.remove( this.columns, ( c: any ) => {
      return colMap[ c.prop ];
    } );
  }

  setCommonColumnProperties() {

    const colsToRemove = [];

    colsToRemove.push( 'cost_basis' );
    colsToRemove.push( 'gain_loss' );

    if ( !this._state.globalVars.showRE ) {
      colsToRemove.push( 'real_assets' );
    }

    const nativeElem = this._elRef.nativeElement;
    const tableWidth = ( nativeElem.offsetParent || nativeElem.parentNode ).clientWidth - 90;
    const calculatedWidth = tableWidth / this.columns.length;
    for ( const col of this.columns ) {
      if ( this.deviceIsMobile ) {
        delete col.frozenLeft;
      }
      col.headerTemplate = this.headerTemplate;
      // col.width = 100;
      const minWidth = col.prop === 'ticker_name' ? 200 : 90;
      col.width = calculatedWidth <= minWidth ? minWidth : calculatedWidth;
      // col.name = this.getColumnName( col );
    }
    this.removeColumns( colsToRemove );
  }

  toggleInfoType( type: any ) {

    if ( this.deviceIsMobile ) {
      this.columns = [
        ...this.leftSideColumns.filter( ( col: any ) => {
          return this.mobileColumnsList.includes( col.prop );
        } ) ];
    } else {
      this.columns = [
        ...this.leftSideColumns,
        ...this.getColumns( type ),
      ];
    }
    this.setCommonColumnProperties();
    this.triggerRerenderOfDatatable();
    // this.getDataGrid().recalculate();
  }

  getAsOfDate( row: any ): string {
    if ( row.quote && row.quote.asOfDate ) {
      return `As Of ${ this.ripDatePipe.transform( row.quote.asOfDate ) }`;
    } else if ( row.security_type === 'Mutual Fund' ) {
      return 'Previous Close';
    } else {
      return null;
    }

    /*else if ( this.account.isManual ) {
     return `As Of ${ this.ripDatePipe.transform( this.account.updated_at ) }`;
     }*/
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openDisclaimerModal( index: number ) {
    if ( this.deviceIsMobile ) {
      this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_DISCLAIMERS, { disclaimerIndex: index } );
    } else {
      this.disclaimersUtil.openDisclaimersDialog( index );
    }
  }

  triggerRerenderOfDatatable() {
    if ( this.rows && this.rows.length > 0 ) {
      // these two lines are purely a way to trigger the datatable to re-render so that the columns are re-sized
      // properly after the addition of new columns
      setTimeout( () => {
        const grid = this.getDataGrid();
        if ( grid ) {
          grid.recalculate();
          this._elRef.nativeElement.querySelector( 'datatable-body' ).scrollTop = 10;
          this._elRef.nativeElement.querySelector( 'datatable-body' ).scrollTop = 0;
          this._elRef.nativeElement.querySelector( 'datatable-body' ).scrollLeft = 10;
          this._elRef.nativeElement.querySelector( 'datatable-body' ).scrollLeft = 0;
        }
      } );
    }
  }

  getRowClass( /*row: any*/ ) {
    return {};
  }

  refreshPrices() {
    this.pricesRefreshing = true;
    if ( !this._state.globalVars.refreshingPrices ) {
      this._accountManager.refreshPrices();
    }
    setTimeout( () => {
      if ( this.pricesRefreshing ) {
        this.pricesRefreshing = false;
      }
    }, 5000 );
  }

  toggleExpandRow( row: Holding, expanded: boolean ): void {
    // Logger.info( `row was expanded: ${ expanded }`, false );
    this.getDataGrid().rowDetail.toggleExpandRow( row );
    if ( expanded ) {
      this.rows = [ ...this.rows ];
    }
  }

  /*onDetailToggle( event ) {
   Logger.info( `Detail Toggled: ${JSON.stringify(event) `, false );
   }*/

  goToAccount( location: string ) {
    this._router.navigate( [ 'pages/accounts' ], { queryParams: { location } } );
  }

  filterHoldings( event: any ) {
    if ( event.column && event.operator && ( event.value || event.value === 0 ) ) {
      this.filters = event;
      if ( this.filters.column.unit && this.filters.column.unit === '%' ) {
        this.filters.value /= 100;
      }
      this.aggregateHoldings();
    } else {
      // maybe tell the user all three things need to be chosen or could just disable filter button until they are
    }
  }

  clearFilter() {
    if ( this.filters.column ) {
      this.filters = {};
      this.aggregateHoldings();
    }
  }

  expandAll() {
    const grid = this.getDataGrid();
    grid.rowDetail.expandAllRows();
  }

  collapseAll() {
    const grid = this.getDataGrid();
    grid.rowDetail.collapseAllRows();
  }

  rowClicked( row: Holding ) {
    this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_ROW_DETAIL, row );
  }

  getRowIndexByTicker( ticker: string ) {
    return this.rows.findIndex( ( h: Holding ) => {
      return h.ticker === ticker;
    } );
  }

  /*
   * Flip the view between table and charts
   * @param column {object} - column object where the flip was triggered from. used to go to the corresponding slide in the carousel
   * */
  flipTable( column?: any ) {

    if ( !this.showTable ) {
      // going back to the table, so let's record the index the chart carousel was at so we might go back to it
      // this.currentChartIndex = this.allocationCharts.chartCarousel.stepper.selectedIndex;
    }

    this.showTable = !this.showTable;
    this.flip = ( this.flip === 'inactive' ) ? 'active' : 'inactive';
  }

  goToRowDetail( ticker: string ) {
    if ( !this.showTable ) {
      this.flipTable();
    }
    const rowIndex = this.getRowIndexByTicker( ticker );
    if ( rowIndex >= 0 ) {
      setTimeout( () => {
        const tableBodies = this._elRef.nativeElement.getElementsByClassName( 'datatable-body' );
        if ( tableBodies?.length > 0 ) {
          const tableBody = tableBodies[ 0 ];
          if ( rowIndex > 5 ) {
            const scrollSpot = ( this.tableRowHeight * rowIndex ); // (rowHeight * rowIndex) + extra rowDetail height
            Util.scrollTo( tableBody, scrollSpot, 1000 );
          }
          setTimeout( () => {
            this.getDataGrid().rowDetail.toggleExpandRow( this.rows[ rowIndex ] );
            if ( rowIndex >= this.rows.length - 4 ) {
              const scrollSpot = tableBody.scrollTop + 375;
              Util.scrollTo( tableBody, scrollSpot, 1000 );
            }
          }, 1000 );
        }
      }, 1000 );
    }
  }

  // Override Functions ===========================================================
  openOverrideModal( row: Holding ) {
    // if this is a manual account, we need to use the manualSecurityEditorModal, not the override modal

    const callback = () => {

    };
    const hp: HoldingPosition = row?.positions[ 0 ];

    if ( !hp?.account?.isManual ) {
      if ( hp.position.ticker === 'CUR:USD' ) {
        Util.goToAccount( hp.account.account_id, hp.position.ticker, this._state, this._router );
      } else {
        this.dialog.open( OverrideModalComponent, {
          data: {
            // account: hp.account,
            row: hp.position,
            grid: null,
            forRevision: false,
            callback,
          },
          minWidth: '50vw',
          minHeight: '50vh',
        } );
      }
    } else {
      this._state.notifyDataChanged( EVENT_NAMES.OPEN_ACCOUNT_MANAGEMENT, hp.account.account_id );
    }
  }


  protected readonly Array = Array;
}
